import axios from 'axios';
import { authHeader } from './auth-header';
import { API_URL_CONTRATOS } from './constants';
import { ERR_MSG_CONEXION } from './constants';

class ContratosService {

  getKpis(AppCtx, Index)
  {
    return axios.post(API_URL_CONTRATOS + '/getkpis', {
        AppCtx: `${AppCtx.codigo}`,
        Id:`${AppCtx.id_referencia}`,
        Index: Index
    }, { headers: authHeader() }).then(response => {
      return response.data;
      }
    ).catch(
      err => {
        if(err.response){
          return Promise.reject(err.response.data);          
        } else {
          return Promise.reject(ERR_MSG_CONEXION);
        }        
      }
    );
  }
  
}

export default  new ContratosService();
