<template>
	<div class="p-grid layout-dashboard">
		<div v-if="kpiInvHabilitado" class="p-col-12 p-md-6 p-lg-3" v-on:click="goToRoute('/trxs/factu')">
			<div class="overview-box card">
				<div class="overview-box-title">Ventas</div>
				<Chart type="line" :data="kpiInvData" />
			</div>
		</div>
		<div v-if="kpiCxcHabilitado" class="p-col-12 p-md-6 p-lg-3" v-on:click="goToRoute('/vencidoscxc')">
			<div class="overview-box card">
				<div class="overview-box-title">Cuentas Por Cobrar</div>
				<Chart type="pie" :data="kpiCxcData" />
			</div>
		</div>	

		<div v-if="kpiContratosHabilitado" class="p-col-12 p-lg-6 p-xl-4">
			<div class="overview-box card">
				<div class="overview-box-title">Contratos Proximos a Vencer</div>				
				<DataTable ref="dt" :value="kpiContratoData" class="p-datatable-sm" dataKey="id_contrato" :paginator="true" :rows="5"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loading">
					<Column field="contrato" header="Contrato" headerStyle="width: 30%">
						<template #body="slotProps">
							<span class="p-text-bold">{{slotProps.data.cta_habiente}}</span><br>
							<span>[{{slotProps.data.contrato}}]</span>							
						</template>
					</Column>
					
					<Column field="vigencia_final" header="Vencimiento"  headerStyle="width: 30%">
						<template #body="slotProps">
							<div v-if="slotProps.data.dias_para_vencimiento <= 0" :class="getClaseEstadoContrato(slotProps.data)">
								Vencido
							</div>
							<div v-if="slotProps.data.dias_para_vencimiento > 0" :class="getClaseEstadoContrato(slotProps.data)">
								{{slotProps.data.vigencia_final}}
							</div>	
						</template>
					</Column>										
				</DataTable>
			</div>
		</div>				
		<!-- <div class="p-col-12 p-md-6 p-lg-3">
			<div class="overview-box card">
				<div class="overview-box-value">21</div>
				<div class="overview-box-title">ISSUES</div>
				<img src="assets/layout/images/dashboard/graph-issues.svg" alt="roma"/>
				<div class="overview-box-status">
					+3.0% <i class="pi pi-arrow-circle-up"></i>
				</div>
			</div>
		</div>
		<div class="p-col-12 p-md-6 p-lg-3">
			<div class="overview-box card">
				<div class="overview-box-value">42</div>
				<div class="overview-box-title">MESSAGES</div>
				<img src="assets/layout/images/dashboard/graph-messages.svg" alt="roma"/>
				<div class="overview-box-status">
					+12.5% <i class="pi pi-arrow-circle-up"></i>
				</div>
			</div>
		</div>

		<div class="p-col-12 p-md-8 p-lg-8">
			<div class="card card-w-title">
				<h5>Statistics</h5>
				<Chart type="line" :data="chartData" :options="chartOptions"/>
			</div>
		</div> -->
	</div>
</template>

<script>
	import CxcService from '../service/cxc.service';
	import InvService from '../service/inv.service';
	import ContratosService from '../service/cntrts.service';

	import dayGridPlugin from '@fullcalendar/daygrid';
	import timeGridPlugin from '@fullcalendar/timegrid';
	import interactionPlugin from '@fullcalendar/interaction';

	export default {
		data() {
			return {
				kpiInvHabilitado: this.$store.getters['auth/tieneAcceso']('KPI_FACTURACION_01'),
				kpiInvData: [],
				kpiCxcHabilitado: this.$store.getters['auth/tieneAcceso']('KPI_CXC_01'),
				kpiCxcData: [],
				kpiContratosHabilitado: this.$store.getters['auth/tieneAcceso']('KPI_CNTR_01'),
				kpiContratoData: [],
				products: [],
				loading: false,
				chartData: {
					labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
					datasets: [{
						label: 'Sales',
						data: [12, 19, 3, 5, 2, 3, 9],
						borderColor: [
							'#0F97C7',
						],
						borderWidth: 3,
						borderDash: [5, 5],
						fill: false,
						pointRadius: 3
					}, {
						label: 'Income',
						data: [1, 2, 5, 3, 12, 7, 15],
						backgroundColor: [
							'rgba(187,222,251,0.2)',
						],
						borderColor: [
							'#578697',
						],
						borderWidth: 3,
						fill: true
					},
						{
							label: 'Expenses',
							data: [7, 12, 15, 5, 3, 13, 21],
							borderColor: [
								'#1BA7AF',
							],
							borderWidth: 3,
							fill: false,
							pointRadius: [4, 6, 4, 12, 8, 0, 4]
						},
						{
							label: 'New Users',
							data: [3, 7, 2, 17, 15, 13, 19],
							borderColor: [
								'#E2841A',
							],
							borderWidth: 3,
							fill: false
						}
					]
				},
				chartOptions: {
					responsive: true,
					hover: {
						mode: 'index'
					},
					scales: {
						xAxes: [{
							display: true,
							scaleLabel: {
								display: true,
								labelString: 'Month'
							}
						}],
						yAxes: [{
							display: true,
							scaleLabel: {
								display: true,
								labelString: 'Value'
							}
						}]
					}
				},
				options: {
					plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
					defaultDate: '2019-01-12',
					header: {
						left: 'prev,next',
						center: 'title',
						right: 'dayGridMonth,timeGridWeek,timeGridDay'
					},
					editable: true
				},
				tasksCheckbox: [],
				menuItems: [
					{
						label: 'Save', icon: 'pi pi-fw pi-check'
					},
					{
						label: 'Update', icon: 'pi pi-fw pi-refresh'
					},
					{
						label: 'Delete', icon: 'pi pi-fw pi-trash'
					}
				],
			}
		},
		mounted() {
			if(this.kpiInvHabilitado) {
				this.getKpiInv();
			}

			if(this.kpiCxcHabilitado) {
				this.getKpiCxC();
			}

			if(this.kpiContratosHabilitado){
				this.getKpiContrato();
			}
		},
		methods: {
			getClaseEstadoContrato(contrato){
				if(contrato.dias_para_vencimiento >= 30)
				{
					return 'vigente';
				} else if ((contrato.dias_para_vencimiento > 0) && (contrato.dias_para_vencimiento < 30))
				{
					return 'proximo'
				} else {
					return 'vencido';					
				}
			},

			goToRoute(ruta){
				this.$router.push(ruta);
			},

			getKpiInv() {
				if(!this.kpiInvHabilitado){
					this.kpiInvData = [];
					return;
				}

				InvService.getKpis(this.$store.state.auth.currentAppCtx, 0).then(data => {					
					let meses = [];
					let ventas = [];
					for(var o in data) {
						meses.push(data[o].Clave);
						ventas.push(data[o].Valor);
					}

					this.kpiInvData =  {
						labels: meses,
						datasets: [
							{				
								label: 'Facturación',				
								data: ventas,
								fill: false,
								borderColor: '#42A5F5'
							}
						]
					}		
				}).catch(err => {
					this.kpiInvData = []
					this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
					}
				);	
			},

			getKpiCxC() {
				if(!this.kpiCxcHabilitado){
					this.kpiCxcData = [];
					return;
				}

				CxcService.getKpis(this.$store.state.auth.currentAppCtx, 0).then(data => {					
					this.kpiCxcData =  {
						labels: ['Corriente','a 15','a 30', 'Más de 30'],
						datasets: [
							{
								data: [data[0].Valor, data[1].Valor, data[2].Valor, data[3].Valor],
								backgroundColor: ["green", "#f7df0a", "#f18520", "red"],								
								hoverBackgroundColor: ["green", "#f7df0a", "#f18520", "red"]
							}
						]
					}					
				}).catch(err => {
					this.kpiCxcData = []
					this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
					}
				);	
			},

			getKpiContrato() {
				if(!this.kpiContratosHabilitado){
					this.kpiContratoData = [];
					return;
				}

				this.loading = true;
				ContratosService.getKpis(this.$store.state.auth.currentAppCtx, 0).then(data => {					
					this.kpiContratoData = data;
					this.loading = false;
				}).catch(err => {
					this.kpiContratoData = [];
					this.loading = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
					}
				);	
			},

			toggle(event) {
				this.$refs.menu.toggle(event);
			},
			formatCurrency(value) {
				return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
			}
		}
	}
</script>

<style scoped>
::v-deep(.fc-header-toolbar) {
    display: flex;
    flex-wrap: wrap;
}

.vencido {
  font-weight: bold;
  color: red;
  text-align: center;
}

.proximo {
  font-weight: bold;
  color: orange;
  text-align: center;
}

.vigente {
  font-weight: normal;
  color: green;
  text-align: center;
}

</style>
